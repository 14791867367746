<template>
  <div class="goods_wrap" @click="handleDetail" @mouseenter="show = true" @mouseleave="show = false">
    <div class="img">
      <img :src="item.image.url" alt="" />
      <div class="cuxiao flex_c_c" v-if="item.show_memo">{{ item.show_memo }}</div>
    </div>
    <div class="goods_con">
      <p class="price">￥{{ item.price }}
        <span v-if="item.price1>0" style="text-decoration: line-through;color:rgb(116, 113, 113);font-size: 12px;">{{item.price1}}</span></p>
      <p class="title shenglue_1">{{ item.title }}</p>
      <tagcom :tags="item.tags"></tagcom>
      <div class="main">
        <p class="shenglue_1">{{ item.manufacturer }}</p>
        <p class="shenglue_1">规格：{{ item.specification }}</p>
        <div class="df_box">
          <p>效期：{{ item.validity_date }}</p>
          <p>件装量：{{ item.jzl }}</p>
        </div>
        <div class="df_box">
          <p>单位：{{ item.bzdw }}</p>
          <p>中包装：{{ item.add_sl }}</p>
        </div>
        <p>库存：{{ item.kc_str }}</p>
      </div>
      <div class="df_box" style="margin-top: 10px" @click.stop>
        <div class="number_box">
          <div class="left" @click.stop="handleMinus">
            <i class="el-icon-minus"></i>
          </div>
          <!-- <div class="num">{{ item.cart_number }}</div> -->
          <div class="num">
            <el-input v-model="item.cart_number" @blur="blur(item)"></el-input>
          </div>
          <div class="right c3" @click.stop="handlePlus">
            <i class="el-icon-plus"></i>
          </div>
        </div>
        <div class="add" :class="[item.show_cart == 101 ? '' : 'gray']" @click.stop="addToCart">加入购物车</div>
      </div>
      <div class="tag_xie">
        <!-- <img src="@/assets/img/goods/tj.png" alt="" /> -->
        <!-- <img src="@/assets/img/goods/jxq.png" alt="" /> -->
      </div>
      <div class="sku" v-if="item.kc_str == '无库存'">
        <img src="@/assets/img/goods/kcbz.png" alt="" />
      </div>
      <div class="kegou" v-if="item.show_red">
        <img src="@/assets/img/goods/bg.png" alt="" />
        <p>{{ item.show_red }}</p>
      </div>
      <div class="collect" v-if="show" @click.stop="collectClick">
        <img v-if="item.is_collect == 102" src="@/assets/img/goods/coll.png" alt="" />
        <img v-if="item.is_collect == 101" src="@/assets/img/goods/iscoll.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import tagcom from "@/components/tag.vue";
export default {
  components: {
    tagcom,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    handleDetail() {
      const { href } = this.$router.resolve({
        path: "/index/goodsDetail",
        query: { id: this.item.id },
      });
      window.open(href, "_blank");
    },
    addToCart() {
      if (this.item.show_cart != 101) return;
      let p = {
        id: this.item.id,
        number: this.item.cart_number,
      };
      this.$api("goods.addCart", p).then(() => {
        this.$message.success("加入购物车成功");
        this.$store.dispatch("getBadge");
      });
    },
    collectClick() {
      if (this.item.is_collect == 102) {
        this.$api("goods.addCollect", { id: this.item.id }).then(() => {
          this.$message.success("收藏成功");
          this.item.is_collect = 101;
        });
      } else {
        this.$api("account.delCollect", { id: this.item.id }).then(() => {
          this.$message.success("取消收藏成功");
          this.item.is_collect = 102;
        });
      }
    },
    handleMinus() {
      let { show_cart, can_buy, can_buy_str, step, add_sl, cart_number } = this.item;

      if (show_cart == 102) return;
      if (can_buy == 102) {
        this.$message.error(can_buy_str); //101可购买 102禁止购买
        return;
      }

      if (Number(step) >= Number(cart_number)) {
        this.$message.error("不能再减少了");
        return;
      }

      this.item.cart_number = Number(cart_number) - Number(add_sl);
    },
    handlePlus() {
      let { show_cart, can_buy, can_buy_str, add_sl, cart_number, max_number } = this.item;

      if (show_cart == 102) return;
      if (can_buy == 102) {
        this.$message.error(can_buy_str); //101可购买 102禁止购买
        return;
      }
      if (Number(cart_number) >= Number(max_number)) {
        this.$message.error("已达库存上限");
        return;
      }

      this.item.cart_number = Number(cart_number) + Number(add_sl);
    },
    blur(item) {
      if (item.add_sl != 1) {
        let num = Number(item.cart_number) / Number(item.add_sl);

        if (num % 1 !== 0) {
          this.item.cart_number = Number(item.add_sl) * (parseInt(num) + 1);
        }
      }
      if (Number(item.cart_number) <= Number(item.step)) {
        this.item.cart_number = item.step;
      }
      if (Number(item.cart_number) >= Number(item.max_number)) {
        this.item.cart_number = item.max_number;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.goods_wrap {
  background-color: #fff;
  width: 230px;
  border: 1px solid #dddddd;
  border-radius: 4px;
  margin-bottom: 12px;
  padding: 10px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  &:hover {
    cursor: pointer;
    box-shadow: 0px 0px 15px 0px rgba(221, 221, 221, 0.5);
  }
  .img {
    margin: 0 auto;
    width: 200px;
    height: 200px;
    background: #eeeeee;
    border-radius: 4px;
    margin-bottom: 12px;
    position: relative;
    .cuxiao {
      position: absolute;
      left: -10px;
      bottom: 0;
      width: 230px;
      padding: 3px 10px;
      box-sizing: border-box;
      background: #000000;
      opacity: 0.65;
      font-size: 12px;
      color: #fff;
    }
  }
  .goods_con {
    width: 100%;
    box-sizing: border-box;
    padding: 0 5px;
    .price {
      font-size: 16px;
      font-weight: bold;
      color: @priceRed;
    }
    .title {
      font-size: 14px;
      font-weight: bold;
      color: #333333;
    }

    .main {
      margin-top: 8px;
      font-size: 12px;
      font-weight: 400;
      line-height: 1.6;
      color: #666666;
    }
    .df_box {
      display: flex;
      justify-content: space-between;
    }
    .number_box {
      width: 91px;
      height: 26px;
      border: 1px solid #dddddd;
      border-radius: 2px;
      display: flex;
      .left {
        color: #ddd;
      }
      .left,
      .right {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .num {
        width: 36px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-left: 1px solid #dddddd;
        border-right: 1px solid #dddddd;
        ::v-deep .el-input__inner {
          padding: 0 4px;
          height: 26px;
          border: none;
          text-align: center;
        }
      }
    }
    .add {
      width: 80px;
      height: 25px;
      background: @themeColor;
      border-radius: 13px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      font-weight: 400;
      color: #fefefe;
      &.gray {
        background: #dddddd;
        color: #999;
      }
    }

    .tag_xie {
      top: 0;
      right: 0;
      position: absolute;
      width: 70px;
      height: 70px;
    }
    .sku {
      width: 100px;
      height: 92px;
      position: absolute;
      top: 65px;
      left: 65px;
    }
    .collect {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 10px;
      left: 10px;
    }
    .kegou {
      top: 0;
      left: 0;
      position: absolute;
      width: 110px;
      height: 30px;
      p {
        font-size: 12px;
        position: absolute;
        top: 6px;
        left: 10px;
      }
    }
  }
}
</style>
